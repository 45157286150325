import { useController, UseControllerProps, useFormContext, get } from "react-hook-form";
import { DripsyTextInputProps } from "@dripsy/core/build/components/TextInput"
import { View, TextInput, Text } from "../../ui";

interface FormTextInputProps extends DripsyTextInputProps, UseControllerProps {
    name: string
    label?: string | React.ReactElement
    defaultValue?: string
}

export default function FormTextInput(props: FormTextInputProps) {
    const { name, rules, label, sx, defaultValue, ...inputProps } = props
    const formContext = useFormContext()
    const { formState } = formContext
    const { field } = useController({ name, rules, defaultValue })

    const fieldError = get(formState.errors, name)
    const hasError = !!fieldError

    // const hasError = Boolean(formState?.errors[name])
    // const fieldError = formState.errors[name]

    return (
        <View sx={{ padding: '$2', width: '100%', maxWidth: 400 }}>
            {label && <Text sx={{ color: '$primary', fontWeight: 'bold' }}>{label}</Text>}
            <TextInput
                enterKeyHint={undefined}
                lineBreakStrategyIOS={undefined}
                verticalAlign={undefined}
                inputMode={undefined}
                id={undefined}
                onPointerEnter={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeave={undefined}
                onPointerLeaveCapture={undefined}
                onPointerMove={undefined}
                onPointerMoveCapture={undefined}
                onPointerCancel={undefined}
                onPointerCancelCapture={undefined}
                onPointerDown={undefined}
                onPointerDownCapture={undefined}
                onPointerUp={undefined}
                onPointerUpCapture={undefined}
                aria-label={undefined}
                aria-busy={undefined}
                aria-checked={undefined}
                aria-expanded={undefined}
                aria-selected={undefined}
                aria-labelledby={undefined}
                aria-valuemax={undefined}
                aria-valuemin={undefined}
                aria-valuenow={undefined}
                aria-valuetext={undefined}
                aria-hidden={undefined}
                aria-disabled={undefined}
                aria-live={undefined}
                aria-modal={undefined}
                role={undefined}
                cursorColor={undefined}
                onPressIn={undefined}
                onPressOut={undefined}
                accessibilityLabelledBy={undefined}
                accessibilityLanguage={undefined}
                autoComplete={undefined}
                onChangeText={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                placeholder={`${label}`}
                sx={{ bg: '$gray', padding: '$2', marginY: '$2', width: '100%', ...sx }}
                {...inputProps} />

            {hasError && fieldError &&
                <Text sx={{ color: '$error', fontSize: '$0' }}>
                    {fieldError.message && fieldError.message.toString()}
                </Text>
            }

        </View>
    )
}