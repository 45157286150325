
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { Container, H3, View, Text, TextInput, SafeAreaView } from "../components/ui"
import ForgotPasswordForm from "../components/login/ForgotPasswordForm"
import { Pressable } from "../components/ui/Pressable";
import { useMember } from "../hooks/useMember"
import { useState } from "react"
import ChangePasswordForm from "../components/login/ChangePasswordForm"

export default function ForgotPasswordScreen() {
    const navigation = useNavigation<NativeStackNavigationProp<any>>()
    const { validateCodeByHash } = useMember()
    const [hash, setHash] = useState("")
    const [emailCode, setEmailCode] = useState("")
    const [showCodeInput, setShowCodeInput] = useState(false)
    const [showChangePwd, setShowChangePwd] = useState(false)
    const [codeError, setCodeError] = useState("")

    function handleFinish(hash: string[]) {
        if (hash.length > 0) {
            setHash(hash[0])
            setShowCodeInput(true)
        }
    }

    function handleCancel() {
        navigation.goBack()
    }

    const validateEmailCode = async () => {
        try {
            const newHash = await validateCodeByHash(emailCode, hash)
            if (newHash.length > 0) {
                setHash(newHash[0])
                setCodeError("")
                setShowChangePwd(true)
                setShowCodeInput(false)
            }
        } catch (err: any) {
            setCodeError(err.toString())
        }
    }

    return (
        <View sx={{ flex: 1, padding: '$2', backgroundColor: '#fff' }}>
            <Container variant="form">
                <H3 sx={{ color: '$primary', textAlign: 'center', textTransform: 'uppercase' }}>Forgot Password</H3>
                {showCodeInput ? (
                    <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center', paddingX: '$4' }}>
                        {codeError.length > 0 && <Text sx={{ color: 'white', bg: '$error', fontWeight: 'bold', padding: '$2', borderRadius: 5, minWidth: 400, textAlign: 'center', marginBottom: '$2' }}>{codeError}</Text>}
                        <Text sx={{ textAlign: 'center' }}>Please check your email for the validation code</Text>
                        <TextInput
                            autoCorrect={false}
                            onChangeText={setEmailCode}
                            maxLength={5}
                            sx={{ bg: '$gray', padding: '$2', marginY: '$2', width: 200 }}
                            placeholder="Email Validation Code"
                            placeholderTextColor="gray"
                            value={emailCode} verticalAlign={undefined} id={undefined} onPointerEnter={undefined} onPointerEnterCapture={undefined} onPointerLeave={undefined} onPointerLeaveCapture={undefined} onPointerMove={undefined} onPointerMoveCapture={undefined} onPointerCancel={undefined} onPointerCancelCapture={undefined} onPointerDown={undefined} onPointerDownCapture={undefined} onPointerUp={undefined} onPointerUpCapture={undefined} aria-label={undefined} aria-busy={undefined} aria-checked={undefined} aria-disabled={undefined} aria-expanded={undefined} aria-selected={undefined} aria-labelledby={undefined} aria-valuemax={undefined} aria-valuemin={undefined} aria-valuenow={undefined} aria-valuetext={undefined} aria-hidden={undefined} aria-live={undefined} aria-modal={undefined} role={undefined} accessibilityLabelledBy={undefined} accessibilityLanguage={undefined} onPressIn={undefined} onPressOut={undefined} lineBreakStrategyIOS={undefined} autoComplete={undefined} enterKeyHint={undefined} inputMode={undefined} cursorColor={undefined} />
                        <Pressable onPress={validateEmailCode} version="buttons.primary">
                            <Text sx={{ color: 'white' }}>Validate Code</Text>
                        </Pressable>
                    </SafeAreaView>
                ) : (
                    <>
                        {showChangePwd ? (
                            <ChangePasswordForm hash={hash} />
                        ) : (
                            <ForgotPasswordForm
                                onFinish={handleFinish}
                                onCancel={handleCancel}
                            />
                        )}
                    </>
                )}
            </Container>
        </View>
    )
}