import { SafeAreaView, Text, View } from "../../components/ui";
import { useState } from "react";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import alert from '../../utils/Common'
import { useMember } from "../../hooks/useMember";
import Member from "../../models/Member";
import FormTextInput from "../ui/forms/FormTextInput";
import { Pressable } from "../ui/Pressable";
import { useConfig } from "../../config/useConfig";

interface FormValues {
    email: string
}

interface ForgotPasswordFormProps {
    onFinish: (hash: string[]) => void
    onCancel: () => void
}

export default function ForgotPasswordForm(props: ForgotPasswordFormProps) {
    const { forgotPassword } = useMember()
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const { email } = data
        try {
            const hash = await forgotPassword(email)
            props.onFinish(hash)
        } catch (err: any) {
            alert("Password Reset Error", err.toString()+'.')
        }
    }

    const onCancel = () => {
        props.onCancel()
    }

    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }

    return (
        <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center', paddingX: '$4' }}>
            <Text sx={{textAlign: 'center'}}>An email with a validation code will be sent to you</Text>
            <FormProvider {...methods}>
                <FormTextInput
                    name="email"
                    label="Email Address"
                    defaultValue=""
                    autoCompleteType="email"
                    textContentType="emailAddress"
                    rules={{
                        required: "Email Required"
                    }}
                    keyboardType="email-address"
                    autoCapitalize="none"
                />

                <View sx={{ width: '100%', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>

                    <Pressable
                        onPress={onCancel}
                        version="buttons.gray">
                        <Text sx={{ color: 'white' }}>Cancel</Text>
                    </Pressable>

                    <Pressable 
                        onPress={methods.handleSubmit(onSubmit, onError)}
                        version="buttons.primary">
                        <Text sx={{ color: 'white' }}>Reset Password</Text>
                    </Pressable>
                </View>
            </FormProvider>
        </SafeAreaView>
    )
}