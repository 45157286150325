import { SafeAreaView, Text, View } from "../../components/ui";
import { useEffect, useState } from "react";
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { KeyboardAvoidingView } from "react-native";
import alert from '../../utils/Common'
import { useMember } from "../../hooks/useMember";
import Member from "../../models/Member";
import FormTextInput from "../ui/forms/FormTextInput";
import { Pressable } from "../ui/Pressable";
import CountryStateSelector from "../ui/forms/CountryStateSelector";

interface FormValues {
    firstName: string
    lastName: string
    company: string
    email: string
    password: string
    repeatPassword: string
    country: string
    state: string
}

interface SignupFormProps {
    onSave: (member: Member) => void
    onCancel: () => void
}

export default function SignupForm(props: SignupFormProps) {
    const { member, countries, states, fetchCountries, fetchStates, createMember } = useMember()
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const canSubmit = methods.formState.isValid && !methods.formState.isSubmitting
    const [country, setCountry] = useState<string>("")

    useEffect(() => {
        async function loadAsync() {
            try {
                await fetchCountries()
            } catch (err: any) {
                console.warn(err.toString())
            }
        }

        loadAsync();
    }, [])

    useEffect(() => {
        async function loadAsync() {
            try {
                await fetchStates(country)
            } catch (err: any) {
                console.warn(err.toString())
            }
        }

        if (country) {
            loadAsync();
        }
    }, [country])

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const { firstName, lastName, password, repeatPassword, email, company, country, state } = data
        try {
            const member = await createMember(firstName, lastName, password, repeatPassword, email, company, country, state)
            props.onSave(member)
        } catch (err: any) {
            alert("Account Creation Error", err.toString()+'.')
        }
    }

    const onCancel = () => {
        props.onCancel()
    }

    const onSelectedCountry = (country: string) => {
        setCountry(country)
        methods.setValue("country", country)
        methods.resetField("state")
    }

    const onSelectedState = (state: string) => {
        methods.setValue("state", state)
    }

    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }

    return (
        <>
            <KeyboardAvoidingView
                behavior="padding"
                enabled={true}
                keyboardVerticalOffset={240}
                style={{ flex: 1 }}>

                <SafeAreaView sx={{ justifyContent: 'center', alignItems: 'center', paddingX: '$4' }}>
                    <FormProvider {...methods}>
                        <FormTextInput
                            name="firstName"
                            label="First Name"
                            rules={{
                                required: "First Name Required"
                            }}
                        />
                        <FormTextInput
                            name="lastName"
                            label="Last Name"
                            rules={{
                                required: "Last Name Required"
                            }}
                        />
                        <FormTextInput
                            name="email"
                            label="Email Address"
                            autoCompleteType="email"
                            textContentType="emailAddress"
                            rules={{
                                required: "Email Required"
                            }}
                            keyboardType="email-address"
                            autoCapitalize="none"
                        />
                        <FormTextInput
                            name="company"
                            label="Company"
                            rules={{
                                required: "Company Required"
                            }}
                        />
                        <FormTextInput
                            name="password"
                            label="Password"
                            placeholder="********"
                            autoCompleteType="password"
                            textContentType="password"
                            secureTextEntry={true}
                            rules={{
                                required: "Password Required",
                                minLength: { value: 8, message: "Min Length 8" }
                            }}
                        />
                        <FormTextInput
                            name="repeatPassword"
                            label="Repeat Password"
                            placeholder="********"
                            autoCompleteType="password"
                            textContentType="password"
                            secureTextEntry={true}
                            rules={{
                                required: "Repeat Password Required",
                                minLength: { value: 8, message: "Min Length 8" },
                                validate: {
                                    matchesPassword: (value) => {
                                        const { password } = methods.getValues()
                                        return password === value || "Password don't match"
                                    }
                                }
                            }}
                        />

                        <View sx={{ position: 'relative', width: '100%', maxWidth: 400 }}>
                            <CountryStateSelector onSelectState={onSelectedState} onSelectCountry={onSelectedCountry} selectedCountry={methods.getValues().country} selectedState={methods.getValues().state} countries={countries} states={states} />
                        </View>
                    </FormProvider>
                </SafeAreaView>
            </KeyboardAvoidingView>
            <View sx={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: '$4' }}>
                <Pressable
                    onPress={onCancel}
                    version="buttons.gray">
                    <Text sx={{ color: 'white' }}>Cancel</Text>
                </Pressable>
                
                <Pressable 
                    onPress={methods.handleSubmit(onSubmit, onError)}
                    version="buttons.primary">
                    <Text sx={{ color: 'white' }}>Create</Text>
                </Pressable>
            </View>
        </>
    )
}