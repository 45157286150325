import { Dimensions, ScrollView } from "react-native"
import { useTrainingMgmt } from "../../hooks/useTrainingMgmt"
import { ActivityIndicator, Text, View, H2, H3 } from "../../components/ui"
import { Pressable as NativePressable, useDripsyTheme } from "dripsy"
import { RosterInvitee, TrainingMgmt } from "../../models/TrainingMgmtData"
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react"
import FormTextInput from "../ui/forms/FormTextInput"
import { MaterialIcons } from '@expo/vector-icons'
import { Pressable } from "../ui/Pressable"
import { formatName, formatEmail, EmailUsername } from "../../utils/Common";
import alert from "../../utils/Common"
import BrandEmailLink from "../ui/BrandEmailLink";
import { useMediaQuery } from "react-responsive";
import { saveAs } from '@progress/kendo-file-saver';
import { Event } from "../../models/Event";

interface FormValues {
    firstName: string
    lastName: string
    email: string
}

interface RosterViewProps {
    training: TrainingMgmt
    event?: Event
}

export default function RosterView(props: RosterViewProps) {
    const { loading, roster, fetchRoster, updateRoster, removeRoster, downloadAttendanceRecords, attendance, fetchAttendance } = useTrainingMgmt()
    const { training, event } = props
    const [updateError, setUpdateError] = useState("")
    const [formSuccess, setFormSuccess] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [windowSize, setWindowSize] = useState(0)
    const [sortKey, setSortKey] = useState("index");
    const [sortDirection, setSortDirection] = useState(true)
    const { theme } = useDripsyTheme()
    const { ...methods } = useForm<FormValues>({ mode: 'onBlur' })
    const isMobile = useMediaQuery({ query: '(max-width:1280px)' })

    useEffect(() => {
        setWindowSize(Dimensions.get('window').height - 200)
        async function loadAsync() {
            await fetchRoster(training.id, event?.id);
            await fetchAttendance(training.reggieId);
        }
        if (!training.isDuplicate) {
            loadAsync()
        }
    }, [])

    const Widths = {
        index: 55,
        firstName: 180,
        lastName: 180,
        email: 230,
        hoursAttended: 120,
        percentageAttended: 150,
        day: 150,
        delete: 65
    };

    var bodyWidth = 0;
    for (const [key, value] of Object.entries(Widths)) {
        if (key != 'day' && key != 'hoursAttended' && key != 'percentageAttended' || (key == 'day' && attendance && attendance.dates && attendance.dates.length > 0)) {
            bodyWidth += value;
        }
    }

    if (training.showAttendance) {
        bodyWidth += Widths.hoursAttended;
        bodyWidth += Widths.percentageAttended;
        if (attendance && attendance.dates && attendance.dates.length > 0) {
            for (let index = 1; index < attendance.dates.length; index++) {
                bodyWidth = bodyWidth + Widths.day;
            }
        }
    }

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        setFormSuccess(false)
        setUpdateError('');
        const { email, firstName, lastName } = data
        if (email && firstName && lastName) {
            setSubmitting(true)
            try {
                await updateRoster(training.id, formatName(firstName), formatName(lastName), formatEmail(email), event?.id)
                methods.reset()
                setUpdateError("")
                setFormSuccess(true)
            } catch (err: any) {
                setUpdateError(err.toString())
            } finally {
                setSubmitting(false)
            }
        } else {
            setUpdateError('Make sure all fields have been filled in correctly.');
        }
    }

    const onError: SubmitErrorHandler<FormValues> = (errors, e) => {
        return console.error("Form error(s):", errors)
    }

    const onRemove = async (invitee: RosterInvitee) => {
        alert(`Remove ${invitee.firstName}?`, "", [{
            text: 'OK',
            onPress: async () => {
                try {
                    await removeRoster(invitee.oppId)
                } catch (err: any) {
                    setUpdateError(err.toString())
                }
            }
        }])
    }

    const EmailNote = ({ training }: { training: TrainingMgmt }) => {
        return (
            <View sx={{ paddingTop: 10, paddingBottom: 24 }}>
                <Text>If you need assistance, please email <BrandEmailLink style={{ color: theme.colors.$primary }} username={EmailUsername(training.coordination)} />.
                </Text>
            </View>
        )
    }

    const FormMessaging = () => {
        if (updateError.length > 0) {
            return <Text sx={{ color: 'white', bg: '$error', fontWeight: 'bold', padding: '$2', margin: '$1', borderRadius: 5, textAlign: 'center' }}>{updateError}</Text>
        }

        if (formSuccess) {
            return <Text sx={{ color: '$primary', bg: '$highlight', fontWeight: 'bold', padding: '$2', margin: '$1', borderRadius: 5, textAlign: 'center' }}>Added Successfully</Text>
        }

        return null
    }

    const AddNewRow = () => {
        return (
            <FormProvider {...methods}>

                <FormTextInput
                    name="firstName"
                    label="First Name"
                    defaultValue=""
                    rules={{
                        required: "First Name Required"
                    }}
                />
                <FormTextInput
                    name="lastName"
                    label="Last Name"
                    defaultValue=""
                    rules={{
                        required: "Last Name Required"
                    }}
                />
                <FormTextInput
                    name="email"
                    label="Email"
                    defaultValue=""
                    rules={{
                        required: "Email Required"
                    }}
                />
                <View sx={{ display: 'flex' }}>
                    {submitting ? (
                        <Text sx={{ padding: '$4' }}>Adding to Roster, please wait...</Text>
                    ) : (
                        <Pressable onPress={methods.handleSubmit(onSubmit, onError)} version="buttons.primary">
                            <Text sx={{ color: 'white' }}>Add</Text>
                        </Pressable>
                    )}
                </View>
            </FormProvider>
        )
    }

    if (training.isDuplicate) {
        return (
            <View sx={{ flex: 1, padding: '$4' }}>
                <Text>This class roster can not be accessed at this time. Please contact <BrandEmailLink style={{ color: theme.colors.$primary }} username={EmailUsername(training.coordination)} /> to have this function activated for this class.</Text>
            </View>
        )
    }

    async function getAttendanceFile() {
        downloadAttendanceRecords(training.id, training.name).then(response => response.blob()).then(async (res) => {
            saveAs(res, `attendanceReport_${training.name}.csv`);
        });
    }

    const sortBy = (key: string) => {
        setSortDirection((sortKey == key) ? !sortDirection : true)
        setSortKey(key);
    }

    function sortRoster(key: string, rosterData: RosterInvitee[]) {
        return rosterData.sort((a: RosterInvitee, b: RosterInvitee) => {
            switch (key) {
                case 'index':
                    if (a.index < b.index) return sortDirection ? -1 : 1;
                    if (a.index > b.index) return sortDirection ? 1 : -1;
                    return 0;
                case 'firstName':
                    if (a.firstName.toLowerCase() < b.firstName.toLowerCase()) return sortDirection ? -1 : 1;
                    if (a.firstName.toLowerCase() > b.firstName.toLowerCase()) return sortDirection ? 1 : -1;
                    return 0
                case 'lastName':
                    if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return sortDirection ? -1 : 1;
                    if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return sortDirection ? 1 : -1;
                    return 0
                case 'email':
                    if (a.email.toLowerCase() < b.email.toLowerCase()) return sortDirection ? -1 : 1;
                    if (a.email.toLowerCase() > b.email.toLowerCase()) return sortDirection ? 1 : -1;
                    return 0
                case 'hours_attended':
                    if (a.hoursAttended ? a.hoursAttended : 0 < b.hoursAttended ? b.hoursAttended : 0) return sortDirection ? -1 : 1;
                    if (a.hoursAttended ? a.hoursAttended : 0 > b.hoursAttended ? b.hoursAttended : 0) return sortDirection ? 1 : -1;
                    return 0;
                case 'percentage_attended':
                    if (a.percentageAttended ? a.percentageAttended : 0 < b.percentageAttended ? b.percentageAttended : 0) return sortDirection ? -1 : 1;
                    if (a.percentageAttended ? a.percentageAttended : 0 > b.percentageAttended ? b.percentageAttended : 0) return sortDirection ? 1 : -1;
                    return 0;
                default:
                    key = key.replace(/-/g, "");
                    if ((a && a.dates && a.dates[key] ? a.dates[key] : 0) < (b && b.dates && b.dates[key] ? b.dates[key] : 0)) return sortDirection ? -1 : 1;
                    if ((a && a.dates && a.dates[key] ? a.dates[key] : 0) > (b && b.dates && b.dates[key] ? b.dates[key] : 0)) return sortDirection ? 1 : -1;
                    return 0;
            }
        })
    }

    function AttendanceData(rosterData: RosterInvitee[]) {
        attendance && attendance.students && (Object.entries(attendance.students).map(([contactID, Student]: [string, any]) => {
            if (Student) {
                rosterData.map((invitee: RosterInvitee, i: number) => {
                    if (invitee.contactId && contactID.toLowerCase() == invitee.contactId.toLowerCase()) {
                        rosterData[i].hoursAttended = Student.hoursAttended;
                        rosterData[i].percentageAttended = Student.percentageAttended;
                        rosterData[i].dates = {};
                        if (Student.dates) Object.entries(Student.dates).map(([date, hours]: [string, any]) => {
                            rosterData[i].dates[date] = hours;
                        })
                    }
                })
            }
        }));
        return rosterData;
    }

    function AttendanceHeader() {
        return (
            <>
                {attendance && attendance.dates.length > 0 && (
                    attendance.dates.map((item: string, key: number) => (
                        <NativePressable key={key} sx={{ width: Widths.day, margin: 0, textAlign: 'left', padding: '$2' }} onPress={() => sortBy(item)}>
                            <Text sx={{ fontWeight: 'bold', margin: 'auto', display: 'flex', color: theme.colors.$highlightText }}>
                                <Text sx={{ height: 19, margin: isMobile ? 'auto' : 0 }}>{item}</Text>
                                {sortKey == item && <MaterialIcons
                                    size={21}
                                    name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"}
                                    color={theme.colors.$highlightText}
                                    sx={{ display: 'inline-block' }}
                                    style={{ marginTop: -2, top: 1, left: 5 }} />}
                            </Text>
                        </NativePressable>
                    ))
                )}
            </>
        );
    }

    const NonEditableRows = ({ records = [] }: { records?: RosterInvitee[] }): any => {
        if (records.length == 0) {
            roster.forEach((e: any, i: number) => {
                e.index = i + 1;
                records.push(e);
            })
        }
        return (
            sortRoster(sortKey, AttendanceData(records)).map((invitee: RosterInvitee, i: number) => (
                <View key={i} nativeID={invitee.oppId} sx={{ flex: 1, alignSelf: 'stretch', flexDirection: 'row', justifyContent: 'flex-start', borderBottomWidth: 1, borderBottomColor: '$highlight' }}>
                    <Text sx={{ ...theme.tableCell, width: Widths.index, textAlign: 'center', ...theme.verticalAlign }}>{invitee.index}</Text>
                    <Text sx={{ ...theme.tableCell, width: Widths.firstName, ...theme.verticalAlign }}>{invitee.firstName}</Text>
                    <Text sx={{ ...theme.tableCell, width: Widths.lastName, ...theme.verticalAlign }}>{invitee.lastName}</Text>
                    <Text sx={{ ...theme.tableCell, width: Widths.email + (isMobile ? 0 : 100), ...theme.verticalAlign }}>{invitee.email}</Text>
                    {training.showAttendance && (
                        <>
                            <Text sx={{ ...theme.tableCell, width: Widths.hoursAttended, textAlign: 'center', ...theme.verticalAlign }}>{invitee.hoursAttended ? invitee.hoursAttended : 0}</Text>
                            <Text sx={{ ...theme.tableCell, width: Widths.percentageAttended, textAlign: 'center', ...theme.verticalAlign }}>{(invitee.percentageAttended ? invitee.percentageAttended : 0) + '%'}</Text>
                            {attendance && attendance.dates.map((item: any, key: number) => {
                                if (invitee.dates) {
                                    return (<Text key={key} sx={{ ...theme.tableCell, width: Widths.day, textAlign: 'center', ...theme.verticalAlign }}>{invitee.dates[item.replace(/-/g, "")]}</Text>)
                                }
                            })}
                        </>
                    )}
                    {training.rosterEditable &&
                        <View sx={{ flex: 1, justifyContent: 'flex-end', width: Widths.delete, padding: '$2', marginY: '$1', paddingLeft: 0, paddingRight: 0 }}>
                            <NativePressable sx={{ justifyContent: 'flex-end', cursor: 'pointer' }} onPress={() => onRemove(invitee)}>
                                <MaterialIcons size={24} name="remove-circle" color={theme.colors.$error} style={{ textAlign: 'right', marginRight: 10 }} />
                            </NativePressable>
                        </View>
                    }
                </View>
            ))
        )
    }

    return (
        <>
            <ScrollView
                automaticallyAdjustKeyboardInsets={true}
                style={{ paddingBottom: theme.space.$5, paddingHorizontal: theme.space.$3, maxHeight: windowSize, width: '100%' }}>
                <H2 sx={theme.styles.header}>Manage Roster</H2>
                {loading ? (
                    <ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />
                ) : (
                    <View sx={{ alignItems: 'center' }}>
                        {!training.rosterEditable && new Date() <= new Date(event ? event.startDate : training.startDate) ?
                            (
                                <View sx={{ padding: '$4' }}>
                                    <Text>It is too close to the start date of the class to edit this roster using this form.  Please email <BrandEmailLink style={{ color: theme.colors.$primary }} username={EmailUsername(training.coordination)} /> to request edits to the roster or to have this form re-activated.</Text>
                                </View>
                            ) : (<EmailNote training={training} />)
                        }
                        <View sx={{ maxWidth: (isMobile ? 710 : 960) + 'px', width: '100%', overflowX: 'scroll', overflowY: 'hidden' }}>
                            <View sx={{ flex: 1, width: bodyWidth + (isMobile ? 0 : 250), overflowX: 'hidden' }}>
                                <View sx={{ flexDirection: 'row', justifyContent: 'flex-start', bg: '$highlight' }}>
                                    <NativePressable sx={{ width: Widths.index, padding: '$2', marginY: '$1', paddingTop: '$2', textAlign: 'center' }} onPress={() => sortBy('index')}>
                                        <Text sx={{ fontWeight: 'bold', display: 'flex', color: theme.colors.$highlightText, textAlign: 'center' }}>
                                            <Text sx={{ height: 19, textAlign: 'center', width: '100%' }}>#</Text>
                                            {sortKey == 'index' && <MaterialIcons size={21} name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"} color={theme.colors.$highlightText} sx={{ display: 'inline-block' }} style={{ marginTop: -2, top: 1, left: 5 }} />}
                                        </Text>
                                    </NativePressable>
                                    <NativePressable sx={{ width: Widths.firstName, padding: '$2', marginY: '$1', paddingTop: '$2' }} onPress={() => sortBy('firstName')}>
                                        <Text sx={{ fontWeight: 'bold', display: 'flex', color: theme.colors.$highlightText }}>
                                            <Text sx={{ height: 19 }}>First Name</Text>
                                            {sortKey == 'firstName' && <MaterialIcons size={21} name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"} color={theme.colors.$highlightText} sx={{ display: 'inline-block' }} style={{ marginTop: -2, top: 1, left: 5 }} />}
                                        </Text>
                                    </NativePressable>
                                    <NativePressable sx={{ width: Widths.lastName, padding: '$2', marginY: '$1', paddingTop: '$2' }} onPress={() => sortBy('lastName')}>
                                        <Text sx={{ fontWeight: 'bold', display: 'flex', color: theme.colors.$highlightText }}>
                                            <Text sx={{ height: 19 }}>Last Name</Text>
                                            {sortKey == 'lastName' && <MaterialIcons size={21} name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"} color={theme.colors.$highlightText} sx={{ display: 'inline-block' }} style={{ marginTop: -2, top: 1, left: 5 }} />}
                                        </Text>
                                    </NativePressable>
                                    <NativePressable sx={{ width: Widths.email + (isMobile ? 0 : 100), padding: '$2', marginY: '$1', paddingTop: '$2' }} onPress={() => sortBy('email')}>
                                        <Text sx={{ fontWeight: 'bold', display: 'flex', color: theme.colors.$highlightText }}>
                                            <Text sx={{ height: 19 }}>Email</Text>
                                            {sortKey == 'email' && <MaterialIcons size={21} name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"} color={theme.colors.$highlightText} sx={{ display: 'inline-block' }} style={{ marginTop: -2, top: 1, left: 5 }} />}
                                        </Text>
                                    </NativePressable>
                                    {training.showAttendance &&
                                        <>
                                            <NativePressable sx={{ width: Widths.hoursAttended, padding: '$2', marginY: '$1' }} onPress={() => sortBy('hours_attended')}>
                                                <Text sx={{ fontWeight: 'bold', margin: 'auto', display: 'flex', color: theme.colors.$highlightText }}>
                                                    <Text sx={{ height: 19 }}>Hours</Text>
                                                    {sortKey == 'hours_attended' && <MaterialIcons size={21} name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"} color={theme.colors.$highlightText} sx={{ display: 'inline-block' }} style={{ marginTop: -2, top: 1, left: 5 }} />}
                                                </Text>
                                            </NativePressable>
                                            <NativePressable sx={{ width: Widths.percentageAttended, textAlign: 'left', padding: '$2', marginY: '$1' }} onPress={() => sortBy('percentage_attended')}>
                                                <Text sx={{ fontWeight: 'bold', display: 'flex', margin: 'auto', color: theme.colors.$highlightText }}>
                                                    <Text sx={{ height: 19, margin: isMobile ? 'auto' : 0 }}>{!isMobile && training.showAttendance ? 'Percentage' : '%'}</Text>
                                                    {sortKey == 'percentage_attended' && <MaterialIcons size={21} name={sortDirection ? "arrow-drop-up" : "arrow-drop-down"} color={theme.colors.$highlightText} sx={{ display: 'inline-block' }} style={{ marginTop: -2, top: 1, left: 5 }} />}
                                                </Text>
                                            </NativePressable>
                                            <AttendanceHeader />
                                        </>
                                    }
                                    {training.rosterEditable &&
                                        <NativePressable sx={{ width: Widths.delete, padding: '$2', marginY: '$1', paddingTop: '$2' }}>
                                            <Text sx={{ fontWeight: 'bold', textAlign: 'right', display: 'flex', color: theme.colors.$highlightText }}>
                                                <Text sx={{ height: 19 }}>Delete</Text>
                                            </Text>
                                        </NativePressable>
                                    }
                                </View>
                                {roster.length > 0 ? (
                                    <View sx={{ borderWidth: 1, borderColor: '$highlight' }}>
                                        <NonEditableRows />
                                    </View>
                                ) : (
                                    <Text sx={{ padding: '$2', textAlign: 'center', borderWidth: 1, borderColor: '$highlight' }}>Roster Empty</Text>
                                )}
                            </View>
                        </View>
                        {training.rosterEditable &&
                            <>
                                <View sx={{ alignItems: 'left' }}>
                                    <Text sx={{ fontStyle: 'italic', marginX: 7, marginY: 8 }}>Roster max: {training.rosterMax}</Text>
                                </View>
                                <H3>Add To Roster</H3>
                                {(training.program && !event) && <Text sx={{ fontWeight: 'bold' }}>Will add contact to all program classes</Text>}
                                <FormMessaging />
                                {roster.length < training.rosterMax ? (
                                    <AddNewRow />
                                ) : (
                                    <Text>This event is full, please email <BrandEmailLink style={{ color: theme.colors.$primary }} username={EmailUsername(training.coordination)} /> to request to increase the class capacity.</Text>
                                )}
                            </>
                        }
                    </View>
                )}
            </ScrollView>
            {!loading && training.showAttendance &&
                <View sx={{ position: 'absolute', bottom: 8, right: 9, flexFlow: 'row', justifyContent: 'flex-end' }}>
                    <Pressable version='buttons.gray' onPress={() => getAttendanceFile()}>
                        <Text sx={{ color: 'white' }}>Export CSV</Text>
                    </Pressable>
                </View>
            }
        </>
    )
}