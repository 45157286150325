import { useContext } from "react";
import { useMemberApi } from "../api/useMemberApi";
import { MemberContext } from "../providers/MemberProvider";
import Member, { Address } from "../models/Member";
import Storage from "../utils/Storage";

export function useMember() {
    const { postLogin, postLogout, postForgotPassword, patchUpdate, postCreate, postLoginHubID, postValidateCode, postValidateCodeByHash, getEmailCode, postConfirmHubID, getTestConnectionDetails, postUpdatePassword, getCurrentMember } = useMemberApi()
    const { loading, member, quotes, notifications, countries, states, shippingStates, contacts, roles, showHeader, bannerAd, trainingMode, updateMember, fetchQuotes, fetchMember, fetchNotifications, fetchCountries, fetchStates, fetchShippingStates, fetchContacts, fetchRoles, setHeader, updateTrainingMode } = useContext(MemberContext)

    async function login(email: string, password: string): Promise<Member> {
        const member = await postLogin(email, password)
        updateMember(member)
        return member
    }

    async function loginHubID(studentHubId: string): Promise<Member> {
        const member = await postLoginHubID(studentHubId)
        updateMember(member)
        return member
    }

    async function logout() {
        try {
            await postLogout()
            updateMember(undefined)
        } catch (err: any) {
            console.log("Logout Error", err)
        }
    }

    async function saveMember(email: string, firstName: string, lastName: string, company: string, role: string, password: string, country: string, state: string, street: string, city: string, zip: string, shipping: Address, shippingSameAsContact: boolean, brand: string): Promise<Member> {
        const member = await patchUpdate(email, firstName, lastName, company, role, password, country, state, street, city, zip, shipping, shippingSameAsContact, brand)
        updateMember(member)
        return member
    }

    async function createMember(firstName: string, lastName: string, password: string, repeatPassword: string, email: string, company: string, country: string, state: string): Promise<Member> {
        const member = await postCreate(firstName, lastName, password, repeatPassword, email, company, country, state)
        updateMember(member)
        return member
    }

    async function savePassword(password: string, hash: string): Promise<boolean> {
        await postUpdatePassword(password, hash)
        return true
    }

    async function forgotPassword(email: string): Promise<string[]> {
        return postForgotPassword(email)
    }

    async function validateCode(code: string): Promise<Member> {
        const member = await postValidateCode(code)
        updateMember(member)

        if (member.token) {
            Storage.set('token', member.token)
        }

        return member;
    }

    async function validateCodeByHash(code: string, hash: string): Promise<string[]> {
        return await postValidateCodeByHash(code, hash)
    }

    return {
        loading,
        member,
        quotes,
        notifications,
        countries,
        states,
        shippingStates,
        contacts,
        roles,
        showHeader,
        bannerAd,
        trainingMode,
        fetchMember,
        login,
        loginHubID,
        logout,
        forgotPassword,
        fetchQuotes,
        fetchNotifications,
        fetchCountries,
        fetchStates,
        fetchShippingStates,
        fetchContacts,
        fetchRoles,
        setHeader,
        saveMember,
        createMember,
        validateCode,
        validateCodeByHash,
        getEmailCode,
        postConfirmHubID,
        getTestConnectionDetails,
        updateTrainingMode,
        savePassword
    }
}