import FormTextInput from "./FormTextInput"
import { useState } from "react"
import { Picker } from '@react-native-picker/picker'
import { AddressTypes, CountryState } from "../../../models/Member"
import { useDripsyTheme } from "dripsy"

type CountryStateSelectorProps = {
    countries: CountryState[]
    states: CountryState[]
    selectedCountry: string
    selectedState: string
    prefix?: keyof AddressTypes
    onSelectState: (state: string, prefix?: keyof AddressTypes) => void
    onSelectCountry: (state: string, prefix?: keyof AddressTypes) => void 
}

export default function CountryStateSelector(props: CountryStateSelectorProps) {
    const { countries, states, selectedCountry, selectedState, prefix } = props
    const [stateProv, setStateProv] = useState<string>("")
    const { theme } = useDripsyTheme()

    const onSelectedCountry = async (country: string) => {
        props.onSelectCountry(country, prefix)
    }

    const onSelectedState = (state: string) => {
        setStateProv(state)
        props.onSelectState(state, prefix)
    }

    return (
        <>
            <Picker
                selectedValue={selectedCountry ? selectedCountry : undefined}
                onValueChange={(itemValue, itemIndex) => {
                    onSelectedCountry(itemValue)
                }}
                style={{ backgroundColor: theme.colors.$gray, padding: theme.space.$2, margin: theme.space.$2, width: '96%', lineHeight: 24 }}
            >
                <Picker.Item key='' label="-- Select Country --" value={null} enabled={true} />
                {countries && countries.map((country, index) => (
                    <Picker.Item key={index} label={country.label} value={country.code} />
                ))}
            </Picker>

            {states.length > 0 ? (
                <Picker
                    selectedValue={stateProv ? stateProv : selectedState}
                    onValueChange={(itemValue, itemIndex) => {
                        onSelectedState(itemValue)
                    }}
                    style={{ backgroundColor: theme.colors.$gray, padding: theme.space.$2, margin: theme.space.$2, width: '96%', lineHeight: 24 }}
                >
                    <Picker.Item key='' label="-- Select State/Province --" value={null} enabled={true} />
                    {states && states.map((state, index) => (
                        <Picker.Item key={index} label={state.label} value={state.code} />
                    ))}
                </Picker>
            ):(
                <FormTextInput
                    name="state"
                    label="State"
                    value={stateProv ? stateProv : selectedState}
                    onChangeText={(state) => onSelectedState(state)}
                    rules={{
                        required: "State Required"
                    }}
                />
            )}
        </>
    )
}