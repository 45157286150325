import { H1, H2, H3, Text, View, ActivityIndicator, Image } from '../../components/ui'
import { useTrainingMgmt } from '../../hooks/useTrainingMgmt'
import { useMember } from '../../hooks/useMember'
import TrainingDataView from '../TrainingDataView'
import { TrainingMgmt } from '../../models/TrainingMgmtData'
import { Training, ReggieFile } from '../../models/TrainingData'
import { useEffect, useState } from 'react'
import { Linking, Platform } from 'react-native'
import { Pressable } from "../ui/Pressable"
import { CoordinatorQuestionnaireView } from '../training/CoordinatorQuestionnaire'
import TestConnectionView from '../training/TestConnectionView'
import HubModal from '../ui/HubModal'
import { AntDesign } from '@expo/vector-icons';
import { useDripsyTheme } from 'dripsy';
import RosterView from './RosterView';
import { TrainingInstructor } from '../../models/TrainingInstructorData'
import UploadRosterView from './UploadRosterView'
import TrainingFilters from '../../components/ui/TrainingFilters';
import MgmtFilesView from './MgmtFilesView'
import { useNavigation } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import InfoButton from '../ui/InfoButton'
import { Section } from '../../models/Section'
import Storage from "../../utils/Storage";
import { clearParams } from '../../utils/Common'

export default function TrainingMgmtView() {
    const { setHeader, showHeader } = useMember()
    const { loading, trainingMgmtData, refreshTrainingMgmtData } = useTrainingMgmt()
    const [modalVisible, setModalVisible] = useState(false)
    const [filterID, setFilterID] = useState('')
    const [showManagementHeader, setShowManagementHeader] = useState(true);
    const [currentModalContent, setCurrentModalContent] = useState<React.ReactElement>()
    const { theme } = useDripsyTheme()
    const navigation = useNavigation<NativeStackNavigationProp<any>>()

    useEffect(() => {
        if (Platform.OS === 'web') {
            const filterByID = Storage.get('TrainingMgmtEventID')
            if (filterByID) setFilterID(filterByID);
        }
        setHeader(false);
    }, [showHeader])

    const Content = ({ training, section }: { training: TrainingMgmt | Training | TrainingInstructor, section: Section }) => {
        const trainingMgmt = training as TrainingMgmt
        return (
            <View sx={{ bg: '$gray', marginBottom: '$2' }}>
                <View sx={{ flexDirection: Platform.OS === 'web' ? 'row' : 'column', justifyContent: 'center', alignItems: 'center', flexWrap: Platform.OS === 'web' ? 'wrap' : 'nowrap' }}>
                    {trainingMgmt.reggieId > 0 &&
                        <>
                            {!trainingMgmt.program && <InfoButton text={trainingMgmt.rosterEditable ? "Complete Logistics Questionnaire" : "View Questionnaire"} onPress={() => { showCoordinatorQuestionnaireView(trainingMgmt) }} />}
                            <InfoButton text="Manage Roster" onPress={() => { showModalView(trainingMgmt) }} />
                            {(!trainingMgmt.isDuplicate && trainingMgmt.rosterEditable) &&
                                <InfoButton text="Upload Roster" onPress={() => { showUploadRoster(trainingMgmt) }} />
                            }
                            <InfoButton text="Files" onPress={() => showFilesModal(trainingMgmt)} />
                        </>
                    }
                    {trainingMgmt.connectionTest.length > 0 &&
                        <InfoButton text="Complete Connection Test" onPress={() => { showTestConnectionView(trainingMgmt) }} />
                    }
                    {trainingMgmt.showEvaluations && trainingMgmt.surveyPostClassReportLink.length > 0 &&
                        <InfoButton text="Class Evaluation Report" onPress={() => { Linking.openURL(trainingMgmt.surveyPostClassReportLink) }} />
                    }
                    {trainingMgmt.program &&
                        <InfoButton text="Classes in Program" onPress={() => { navigation.navigate('TrainingProgram', { id: training.reggieId }) }} />
                    }
                </View>
            </View>
        )
    }

    const showFilesModal = (training: TrainingMgmt) => {
        showModal(<MgmtFilesView training={training} />)
    }

    const showCoordinatorQuestionnaireView = (trainingMgmt: TrainingMgmt) => {
        showModal(<CoordinatorQuestionnaireView training={trainingMgmt} />)
    }

    const showModalView = (trainingMgmt: TrainingMgmt) => {
        showModal(<RosterView training={trainingMgmt} />);
    }

    const showUploadRoster = (trainingMgmt: TrainingMgmt) => {
        showModal(<UploadRosterView training={trainingMgmt} />);
    }

    const showTestConnectionView = (trainingMgmt: TrainingMgmt) => {
        showModal(<TestConnectionView opportunityId={trainingMgmt.id} />);
    }

    const showModal = (content: React.ReactElement) => {
        setCurrentModalContent(content)
        setModalVisible(true)
    }

    const FooterContent = () => {
        return null
    }

    const clearAllFilters = () => {
        setFilterID('')
        clearParams(['ID'])
        Storage.remove('TrainingMgmtEventID')
        refreshTrainingMgmtData();
    }

    return (
        <>
            <View sx={{ flexDirection: 'column', backgroundColor: '$primary', paddingY: '$3', paddingX: '$3', marginX: '$2', borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}>
                <View>
                    <H3 sx={{ color: '#fff', fontSize: '$1', textAlign: 'left', marginY: 0 }}>Training Management</H3>
                    {/* This is a button to show / hide the additional details in the header. Maybe we should use it if any additional info is added in the future  */}
                    <Pressable sx={{ position: 'absolute', right: 0, left: 0, width: '100%', margin: 0, padding: 0, marginTop: -3, height: 19, flexDirection: 'row', alignItems: 'center' }} onPress={() => { setShowManagementHeader(!showManagementHeader) }}>
                        <AntDesign style={{ width: '100%', textAlign: 'right' }} name={!showManagementHeader ? "up" : "down"} size={24} color={theme.colors.$background} />
                    </Pressable>
                </View>
                <View sx={{ flexDirection: 'row', maxHeight: showManagementHeader ? '100%' : 0, overflow: 'hidden' }}>
                    <View sx={{ alignItems: 'center', marginX: 0, flexDirection: 'row', margin: '$3', marginBottom: '$2', justifyContent: 'space-between' }}>
                        {Platform.OS === 'web' && <TrainingFilters />}
                    </View>
                    <Text sx={{ width: '100%', textAlign: 'center', fontStyle: 'italic', marginX: 'auto', flexDirection: 'row', color: 'white', height: 20, marginTop: 25, marginLeft: 10 }}>
                        <Text sx={{ flexDirection: 'column' }}>
                            {filterID && <Text sx={{ marginRight: 8 }}>Filtering by ID: <b>{filterID}</b></Text>}
                        </Text>
                        {(filterID) &&
                            <Text onPress={() => { clearAllFilters() }} sx={{
                                paddingX: 2,
                                paddingY: 5,
                                borderRadius: '2px',
                                textAlign: 'center',
                                textTransform: 'uppercase',
                                display: 'block',
                                fontSize: 11,
                                fontStyle: 'normal'
                            }}>
                                <Text sx={{ cursor: 'pointer', color: '#fff' }}>Clear Filters</Text>
                            </Text>
                        }
                    </Text>
                </View>
            </View>
            <View sx={{ flex: 1, padding: '$2', paddingTop: 0, backgroundColor: '#fff' }}>

                {!trainingMgmtData ? (<ActivityIndicator sx={{ marginTop: '$4' }} size="large" color="$primary" />) : (
                    (trainingMgmtData?.active.length > 0 || trainingMgmtData?.future.length > 0 || trainingMgmtData?.past.length > 0) ? (
                        <>
                            <TrainingDataView
                                trainingData={trainingMgmtData}
                                loading={loading}
                                showSections={filterID == '' ? true : false}
                                onRefreshTraining={refreshTrainingMgmtData}
                                ItemContent={Content}
                                FooterContent={FooterContent}
                            />
                        </>
                    ) :
                        <View>
                            <H3 sx={{ marginTop: 32, textAlign: 'center' }}>No Results Found</H3>
                        </View>

                )}
                {currentModalContent && <HubModal
                    modalLoading={false}
                    modalVisible={modalVisible}
                    ModalContent={currentModalContent}
                    setModalVisible={setModalVisible} />
                }
            </View>
        </>
    )
}